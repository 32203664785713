.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-color: #34666F;
  border-radius: 2vh;
  border-width: 0.5vh;
  width: 32vw;
}

.mInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-color: #34666F;
  border-radius: 2vh;
  border-width: 0.5vh;
  width: 90vw;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.loading {
  align-items: center;
  margin-top: 2vh;
}
