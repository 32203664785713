.submitButtonDiv {
    margin-top: 2vh;
    text-align: center;
    margin-bottom: 1vh;
  }

.submitButton {
    width: 6.5vw;
    height: 4.3vh;
    font-size: 1.5vh;
    color: white;
    font-weight: bold;
    background-color: #F1D6BC;
    border-color: #F1D6BC;
    border-radius: 0.6vh;
    border-style: solid;
    cursor: pointer;
}

.submitButtonClicked {
  width: 6.5vw;
  height: 4.3vh;
  font-size: 1.5vh;
  color: white;
  font-weight: bold;
  background-color: #F1D6BC;
  border-color: #F1D6BC;
  border-radius: 0.6vh;
  border-style: solid;
  cursor: pointer;
  opacity: 0.5;
}

.mSubmitButton {
  width: 20vw;
  height: 4.3vh;
  font-size: 1.5vh;
  color: white;
  font-weight: bold;
  background-color: #F1D6BC;
  border-color: #F1D6BC;
  border-radius: 0.6vh;
  border-style: solid;
  cursor: pointer;
}

.mSubmitButtonClicked {
  width: 20vw;
  height: 4.3vh;
  font-size: 1.5vh;
  color: white;
  font-weight: bold;
  background-color: #F1D6BC;
  border-color: #F1D6BC;
  border-radius: 0.6vh;
  border-style: solid;
  cursor: pointer;
  opacity: 0.5;
}