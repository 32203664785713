.accessCodeDiv {
    margin-top: 2vh;
}

.mAccessCodeImg {
    height: 5vh;
    width: 28vw;
}

.accessCodeImg {
}

