.userInput {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
}
.userInputLabel {
  font-size: 1vh;
  margin-bottom: 0.5vh;
  text-align: start;
  color: grey
}

.input {
  height: 4vh;
  width: 16vw;
  border-radius: 0.5vh;
  border-color: #FCC81A;
  border-style: solid;
}

.mInput {
  height: 4vh;
  width: 80vw;
  border-radius: 0.5vh;
  border-color: #FCC81A;
  border-style: solid;
}

