.completeDiv {
    display: flex;
    flex-direction: column;
    align-self: center
}

.checkImg {

}

.msg {

}